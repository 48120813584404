<template>
  <div class="web_pla_int">
    <!-- 澳新购 -->
    <div class="item">
      <div>
        <img class="bg-image" src="@/assets/img/az/Vector@2x.png" alt="" />
      </div>
      <div class="aoxingou_det">
        <!-- 澳新购左侧内容 -->
        <div class="">
          <div class="top_det clw100">
            <div class="  wel_top">
              {{ $t("AaAbordTop.introductionBug.title") }}
            </div>
            <div class=" font14 text-des">
              <div class="text-item">
                <div class="pointer"></div>
                <div>{{ $t("AaAbordTop.introductionBug.first") }}</div>
              </div>
              <div class="text-item">
                <div class="pointer"></div>
                <div>{{ $t("AaAbordTop.introductionBug.second") }}</div>
              </div>
              <div class="text-item">
                <div class="pointer"></div>
                <div>{{ $t("AaAbordTop.introductionBug.third") }}</div>
              </div>
              <!-- {{ $t("AaAbordTop.introductionBug.text") }} -->
            </div>
          </div>
          <!-- 底部了解更多 -->
          <div class="bot_btn">
            <div class="font18 det_btn clf100" @click="goApply(1)">
              <span>{{ $t("AaAbordTop.showMore") }}</span>
              <img src="@/assets/img/az/go.png" alt="" />
            </div>
            <!-- 苹果按钮 -->
            <a :href="apple_aoxingou_url">
              <img
                class="apple_download_icon mr_left120"
                :src="$t('AaAbordTop.apple_download_icon')"
                alt=""
              />
            </a>
            <!-- 苹果二维码 -->
            <img
              class="apple_download_code mr_left8"
              :src="$t('AaAbordTop.introductionBug.apple_aoxingou_code')"
              alt=""
            />
            <!-- 安卓按钮 -->
            <img
              v-if="isWxClient && isMobileTerminal"
              class="apple_download_icon mr_left26"
              :src="$t('AaAbordTop.and_download_icon')"
              alt=""
            />
            <a v-else :href="android_aoxingou_url">
              <img
                class="apple_download_icon mr_left26"
                :src="$t('AaAbordTop.and_download_icon')"
              />
            </a>
            <!-- 安卓二维码 -->
            <img
              class="apple_download_code mr_left8"
              :src="$t('AaAbordTop.introductionBug.android_aoxingou_code')"
              alt=""
            />
          </div>
        </div>
        <!-- 澳新购右侧大图 -->
        <img
          class="aoxingou_right_img"
          :src="$t('AaAbordTop.introductionBug.right_logo')"
          alt=""
        />
      </div>
    </div>
    <!-- 澳新通 -->
    <div class="item clf100 block-color">
      <div>
        <img
          class="bg-image second"
          src="@/assets/img/az/Vector1@2x.png"
          alt=""
        />
      </div>
      <div class="aoxingou_det">
        <!-- 澳新通左侧 -->
        <div>
          <div class="top_det">
            <div class="  wel_top">
              {{ $t("AaAbordTop.introductionService.title") }}
            </div>
            <div class=" font14 text-des">
              <div class="text-item">
                <div class="pointer"></div>
                <div>{{ $t("AaAbordTop.introductionService.first") }}</div>
              </div>
              <div class="text-item">
                <div class="pointer"></div>
                <div>{{ $t("AaAbordTop.introductionService.second") }}</div>
              </div>
              <div class="text-item">
                <div class="pointer"></div>
                <div>{{ $t("AaAbordTop.introductionService.third") }}</div>
              </div>
            </div>
          </div>
          <div class="bot_btn">
            <div class="font18 det_btn clf100" @click="goApply(2)">
              <span>{{ $t("AaAbordTop.showMore") }}</span>
              <img src="@/assets/img/az/go.png" alt="" />
            </div>
            <!-- 苹果按钮 -->
            <a :href="apple_aoxintong_url">
              <img
                class="apple_download_icon mr_left120"
                :src="$t('AaAbordTop.apple_download_icon')"
                alt=""
              />
            </a>
            <!-- 苹果二维码 -->
            <img
              class="apple_download_code mr_left8"
              :src="$t('AaAbordTop.introductionService.apple_aoxintong_code')"
              alt=""
            />
            <!-- 安卓按钮 -->
            <img
              v-if="isWxClient && isMobileTerminal"
              class="apple_download_icon mr_left26"
              :src="$t('AaAbordTop.and_download_icon')"
              alt=""
            />
            <a v-else :href="android_aoxintong_url">
              <img
                class="apple_download_icon mr_left26"
                :src="$t('AaAbordTop.and_download_icon')"
              />
            </a>
            <!-- 安卓二维码 -->
            <img
              class="apple_download_code mr_left8"
              :src="$t('AaAbordTop.introductionService.android_aoxintong_code')"
              alt=""
            />
          </div>
        </div>
        <!-- 澳新通右侧大图 -->
        <img
          class="aoxingou_right_img"
          :src="$t('AaAbordTop.introductionService.right_logo')"
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  isIPhoneOrIPad,
  isWxClient,
  isMobileTerminal,
  isAndroid
} from "@/utils/pattern";
export default {
  data() {
    return {
      apple_aoxingou_url:
        "https://apps.apple.com/cn/app/az%E6%BE%B3%E6%96%B0%E8%B4%AD/id1590792955",
      apple_aoxintong_url:
        "https://apps.apple.com/cn/app/az%E6%BE%B3%E6%96%B0%E9%80%9A/id1590793270",
      android_aoxingou_url:
        "https://assets-apk.myazstore.com/app/axg-android.apk",
      android_aoxintong_url:
        "https://assets-apk.myazstore.com/app/axt-android.apk"
    };
  },
  computed: {
    isIPhoneOrIPad() {
      return isIPhoneOrIPad();
    },
    isWxClient() {
      return isWxClient();
    },
    isMobileTerminal() {
      return isMobileTerminal();
    },
    isAndroid() {
      return isAndroid();
    }
  },
  methods: {
    goApply(index) {
      let url = "https://myaz.com.au";
      if (index === 1) {
        url = "https://myaz.com.au";
      } else {
        url = "https://azgwholesale.com.au/";
      }
      window.open(url);
    }
  }
};
</script>

<style lang="less" scoped>
.bg-image {
  width: calc(100vw * 30 / 192);
  position: absolute;
  &.second {
    right: 0;
  }
}
.aoxingou_det {
  display: flex;
  .aoxingou_right_img {
    width: calc(100vw * 17.2 / 192);
    // height: 364px;
    display: block;
    margin: calc(100vw * 6 / 192) calc(100vw * 11.2 / 192);
  }
  .apple_download_icon {
    width: calc(100vw * 9.6 / 192);
    height: calc(100vw * 3.6 / 192);
    display: block;
  }
  .apple_download_code {
    width: calc(100vw * 9 / 192);
    height: calc(100vw * 9 / 192);
    display: block;
  }
  .mr_left120 {
    margin-left: calc(100vw * 12 / 192);
  }
  .mr_left26 {
    margin-left: calc(100vw * 2.6 / 192);
  }
  .mr_left8 {
    margin-left: calc(100vw * 0.8 / 192);
  }
}
.web_pla_int {
  margin-top: calc(100vw * 5 / 192);
  text-align: left;
  .item {
    overflow: hidden;
    background: #ffffff;
    border-radius: 12px;
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.05);
    position: relative;
    width: calc(100vw * 125.6 / 192);
    border: 1px solid #fff;
    font-family: PingFang SC, PingFang SC-Regular;
    text-align: justify;
    border-radius: calc(100vw * 1.2 / 192);
    .text-des {
      color: rgba(0, 0, 0, 0.6);
      line-height: calc(100vw * 2.6 / 192);
    }
    margin: 0 auto;
    margin-bottom: calc(100vw * 5 / 192);
    background-color: linear-gradient(90deg, #31455e, #2a2d36 100%);

    &.block-color {
      color: rgba(2, 76, 130, 0.8);
      // background: linear-gradient(90deg, #31455e, #2a2d36 100%);
      // border-image: linear-gradient(
      //     270deg,
      //     rgba(230, 195, 137, 0.6) 0%,
      //     rgba(230, 195, 137, 0) 100%
      //   )
      //   1 1;
    }
  }
}
.item {
  .top_det {
    // position: absolute;
    margin-left: calc(100vw * 7.2 / 192);
    margin-right: calc(100vw * 7.2 / 192);
    margin-top: calc(100vw * 3.6 / 192);
    margin-bottom: calc(100vw * 3.6 / 192);
    font-size: calc(100vw * 2.8 / 192);
    // z-index: 99;
  }
  .text-item {
    display: flex;
    .pointer {
      display: none;
      line-height: 0;
      &::before {
        content: "";
        display: inline-block;
        width: 4px;
        height: 4px;
        border-radius: 2px;
        background: rgba(0, 0, 0, 0.5);
      }
      margin-top: calc(100vw * 0.8 / 192);
      margin-right: calc(100vw * 1.4 / 192);
    }
  }
  .wel_top {
    margin-bottom: calc(100vw * 3.6 / 192);
  }
  .bot_btn {
    // position: absolute;
    margin-left: calc(100vw * 7.2 / 192);
    margin-bottom: calc(100vw * 3.6 / 192);
    display: flex;
    align-items: center;
  }
  .det_btn {
    z-index: 99;
    display: block;
    color: rgba(0, 0, 0, 0.9);
    padding: calc(100vw * 0.6 / 192) calc(100vw * 3.6 / 192);
    padding-right: calc(100vw * 2 / 192);
    background: #fffaf0;
    border: 1px solid rgba(191, 146, 100, 0.5);
    // border-radius: calc(100vw * 5.4 / 192);
    border-radius: calc(100vw * 0.6 / 192);
    // margin-top: calc(100vw * 1.1 / 192);
    cursor: pointer;
    font-size: calc(100vw * 1.4 / 192);
    font-family: PingFang SC, PingFang SC-Regular;
    > * {
      display: inline-block;
      vertical-align: middle;
    }
    img {
      width: calc(100vw * 2.4 / 192);
      margin-left: calc(100vw * 1 / 192);
    }
  }
}
</style>
